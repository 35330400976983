import GGLogo from '../imagesrc/G&G-2.PNG';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Navbar.css'
import "bootstrap/dist/css/bootstrap.min.css";
import { Component } from "react";



class HeaderNavbar extends Component {
  render() {
    return (
      <div id="header-container">
          <Navbar variant="dark" 
          collapseOnSelect
          expand="lg"
          id="main-nav-bar">
          <Navbar.Brand><img src={GGLogo} className="App-logo" alt="logo" /></Navbar.Brand>
          <Navbar.Toggle id="hamburger-button" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav id="nav-body"className="ms-auto">
                  <Nav.Link className="hover-link" onClick={() => {
                  const anchor = document.querySelector('#hook-container')
                  anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  }}>Home</Nav.Link>
                  <Nav.Link className="hover-link"
                  onClick={() => {
                    const anchor = document.querySelector('#about-title')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                   }}>About Us</Nav.Link>
                  <Nav.Link className="hover-link"
                  onClick={() => {
                    const anchor = document.querySelector('#packages-top')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                   }}>Packages</Nav.Link>
                  <Nav.Link className="hover-link"
                  onClick={() => {
                    const anchor = document.querySelector('#contact-title')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                   }}>Contact</Nav.Link>
                  <Nav.Link href="https://forms.gle/eFsNS7c1CHCKu7Y36" id="book-now-button" target="_blank">Book Now</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
    );
  }
}

export default HeaderNavbar;