import './About.css'
import aboutimage from '../imagesrc/aboutimage.png'
import Fade from 'react-reveal/Fade';
import { Component } from 'react';


class About extends Component {
  render() {
  return (
    <div id="about-container">
      <div id="about-title">
        <Fade>
          <div>
            about <span id="glow-and-gloss">Glow & Gloss Detailing</span>
          </div>
        </Fade>
      </div>
      <div id="about-card">
        <div id="about-card-container">
          <Fade><div id="about-paragraph">
              Welcome to Glow and Gloss Detailing, your premier choice for mobile car detailing 
              services in the Dallas Fort Worth metroplex. With a commitment to excellence and a 
              passion for perfection, we bring the ultimate convenience of professional car care 
              directly to your doorstep. Our team is dedicated to restoring and maintaining the 
              pristine condition of your vehicle, right here in DFW. Whether you need a thorough 
              interior cleaning, or your exterior has been through the mud, we tailor our services 
              to meet your specific needs, ensuring your vehicle looks its absolute best.
          </div></Fade>
          <Fade><img id="about-image" src={aboutimage} alt="aboutimage"></img></Fade>
        </div>
      </div>
    </div>
  );
}
}

export default About;