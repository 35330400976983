import { Component } from 'react';
import './Hook.css'
import Fade from 'react-reveal/Fade';

class Hook extends Component{
  render() {
  return (
    <div>
      <div id="hook-container">
      <Fade>
        <div id="hook-card">
          PREMIUM MOBILE DETAILING IN DFW AREA
        </div>
        </Fade>
      </div>
    </div>
  );
}
}

export default Hook;