import './Packages.css'
import Row from 'react-bootstrap/Row';
import interior from '../imagesrc/interior.png'
import exterior from '../imagesrc/exterior.png'
import theworksimage from '../imagesrc/theworksimage.png'
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';


class Packages extends Component {
  render() {
    return (
      <div id="packages-container">
        <div>
          <div id="packages-title">
            PACKAGES
          </div>
        </div>
        <div id="packages-card">
        <div className="flex-column">
            <Row>
                <Fade><div id="packages-top" className="package-name">EXTERIOR<br/>starting @ $85</div></Fade>
              </Row>
              <Row>
                <Fade><img src={exterior} className="package-image" alt="exterior"></img></Fade>
              </Row>
              <Row>
                <div className="package-description">
                  <ul>
                    <Fade><li>Full Foam Bath</li></Fade>
                    <Fade><li>Gentle Hand Wash</li></Fade>
                    <Fade><li>Detailed Wheel Well and Barrel Cleaning</li></Fade>
                    <Fade><li>High-Gloss Tire Shine and Dressing</li></Fade>
                    <Fade><li>Clear Glass with Water Repellent</li></Fade>
                  </ul>
                </div>
              </Row>
            </div>
            <div className="flex-column">
              <Row>
                <Fade><div className="package-name">INTERIOR<br/>starting @ $115</div></Fade>
              </Row>
              <Row>
                <Fade><img src={interior} className="package-image" alt="interior"></img></Fade>
              </Row>
              <Row>
                <div className="package-description">
                  <ul>
                    <Fade><li>Thorough Vacuum</li></Fade>
                    <Fade><li>Deep Steam Clean for All Surfaces</li></Fade>
                    <Fade><li>Streak-Free Glass</li></Fade>
                    <Fade><li>Spotless Door Jambs</li></Fade>
                    <Fade><li>Interior Protective Dressing</li></Fade>
                  </ul>
                </div>
              </Row>
            </div>
            <div className="flex-column">
              <Row>
                <Fade><div className="package-name">THE WORKS <br/>starting @ $175</div></Fade>
              </Row>
              <Row>
                <Fade><img src={theworksimage} className="package-image" alt="theworks"></img></Fade>
              </Row>
              <Row>
                <div className="package-description">
                  <ul>
                    <Fade><li>Includes Interior & Exterior Packages</li></Fade>
                  </ul>
                </div>
              </Row>
            </div>
        </div>
        <Row>
          <div className="add-ons" id="add-ons-title">
          <Fade>Add-ons: </Fade>
              <ul id="add-ons-list">
                <Fade><li>Pet Hair Removal ($25)</li></Fade>
              </ul>
          </div>
        </Row>
      </div>
    );
  }
}

export default Packages;
