import './App.css';
import HeaderNavbar from '../Navbar/Navbar';
import BodyContainer from '../BodyContainer/BodyContainer';
import { Component } from 'react';

class App extends Component {
  render() {
  return (
    <div className="App">
      <body>
        <HeaderNavbar/>
        <BodyContainer/>
      </body>
    </div>
  );
}
}

export default App;
