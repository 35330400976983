import './Contact.css';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Component } from 'react';
import tiktokicon from '../imagesrc/tiktokicon.png';
import instagramicon from '../imagesrc/instagramicon.png';

class Contact extends Component {
    render() {
        return (
          <div id="contact-container">
            <div id="contact-title">
              <Fade>
                <div>
                  CONTACT US
                </div>
              </Fade>
            </div>
            <div id="contact-card">
              <div id="contact-card-container">
                <div id="contact-paragraph">
                  <Fade>
                    <li>(682) 241-5893</li>
                    <li>glowglossdetailing@gmail.com</li>
                    <li><span><a href="https://www.instagram.com/glowglossdetailing/" target="_blank" rel="noreferrer"><img id="instagram-icon" src={instagramicon} alt="instagram"/></a> @GlowGlossDetailing</span></li>
                    <li><span><a href="https://www.tiktok.com/@glowandglossdetailing?_t=8oDY9IVkRXr&_r=1" target="_blank" rel="noreferrer"><img id="tiktok-icon" src={tiktokicon} alt="tiktok"/></a>@GlowandGlossDetailing</span></li>
                  </Fade>
                  </div>
                  <Fade>
                      <iframe 
                      id="google-form"
                      title="Glow & Gloss Mobile Detailing Google Form" 
                      src="https://docs.google.com/forms/d/e/1FAIpQLSfl-CUlf7efEwhnxq9wz2ozkAOvBGHsLFypy_gMLuWJ6D-_gw/viewform?embedded=true">
                        Loading…</iframe>
                  </Fade>
              </div>
            </div>
          </div>
        );
      }
}


export default Contact;