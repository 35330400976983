import './BodyContainer.css'
import Hook from '../Hook/Hook';
import About from '../About/About';
import Packages from '../Packages/Packages';
import { Component } from 'react';
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer';


class BodyContainer extends Component {
  render() {
  return (
    <div id="body-container">
      <div>
        <Hook/>
      </div>
      <div>
        <About/>
      </div>
      <div>
        <Packages/>
      </div>
      <div>
        <Contact/>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
}
}

export default BodyContainer;