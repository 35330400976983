import './Footer.css';
import React from 'react';
import { Component } from 'react';

class Footer extends Component{
    render() {
      return (
        <div id="footer-container">
          <div id="copyright">
            Copyright © 2024 Glow and Gloss Detailing - All Rights Reserved.
          </div>
        </div>
      );
      }
}

export default Footer;